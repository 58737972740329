<template>
    <div>
        <!-- 정보입력 -->
        <!-- 정보입력: 사업자 -->
        <div class="mb-6">
            <p class="font-size-14 text-right"><span style="color: rgb(255, 0, 0);">*</span> 필수입력입니다.</p>
        </div>
        <div class="table-style table-style--default">
            <div class="table-style__row">
                <div class="table-style__th">아이디 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.username" @input="input" type="text" name="username" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">비밀번호 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.password" @input="input" type="password" name="password" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                    <small class="text-grey text-grey--right">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)</small>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">비밀번호 확인 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.passwordConfirm" @input="input" type="password" name="password2" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">기업명 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.name" @input="input" type="text" name="name" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">대표자명 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.ceoName" @input="input" type="text" name="ceoName" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">기업설립일</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.foundAt" @input="input" type="date" name="foundAt" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">사업자등록번호 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.businessRegisitrationNumber" @input="input" type="text" name="businessRegisitrationNumber" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">국가</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.nation" @input="input" type="text" name="nation" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">연락처 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-text-field v-model="user.phone1" @input="input" type="tel" name="phone1" maxlength="4" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="user.phone2" @input="input" type="tel" name="phone2" maxlength="4" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="user.phone3" @input="input" type="tel" name="phone3" maxlength="4" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <v-btn @click="certify" tile color="primary" class="w-100 w-sm-120px v-btn--input">본인인증</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">이메일 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-text-field v-model="user.email1" type="text" name="email1" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="auto">@</v-col>
                            <v-col>
                                <v-text-field v-model="user.email2" type="text" name="email2" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <select v-model="user.email3" @change="user.email2 = user.email3" class="select w-100">
                                    <option :value="null">직접입력</option>
                                    <option :value="`naver.com`">naver.com</option>
                                    <option :value="`daum.net`">daum.net</option>
                                    <option :value="`google.com`">google.com</option>
                                </select>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">주소 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col cols="9">
                                <v-text-field v-model="user.postcode" @click="searchAddress" type="text" placeholder="우편번호" readonly outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="searchAddress" tile color="primary" class="w-100 v-btn--input">검색</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="user.address1" @click="searchAddress" type="text" placeholder="기본주소" readonly outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="user.address2" type="text" placeholder="상세주소" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">홈페이지</div>
                <div class="table-style__td">
                    <v-text-field v-model="user.website" @input="input" type="text" name="website" outlined hide-details placeholder="https://" class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            
            <div class="table-style__row">
                <div class="table-style__th">담당자 이름 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-text-field v-model="user.workerName" @input="input" type="text" name="workerName" outlined hide-details class="w-100 mw-lg-500px"></v-text-field>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">담당자 이메일 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-text-field v-model="user.workerEmail1" type="text" name="workerEmail1" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="auto">@</v-col>
                            <v-col>
                                <v-text-field v-model="user.workerEmail2" type="text" name="workerEmail2" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <select v-model="user.workerEmail3" @change="user.workerEmail2 = user.workerEmail3" class="select w-100">
                                    <option :value="null">직접입력</option>
                                    <option :value="`naver.com`">naver.com</option>
                                    <option :value="`daum.net`">daum.net</option>
                                    <option :value="`google.com`">google.com</option>
                                </select>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">담당자 연락처 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <div class="mw-lg-500px">
                        <v-row class="row--x-small" align="center">
                            <v-col>
                                <v-text-field v-model="user.workerPhone1" @input="input" type="tel" name="workerPhone1" maxlength="4" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="user.workerPhone2" @input="input" type="tel" name="workerPhone2" maxlength="4" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                <v-text-field v-model="user.workerPhone3" @input="input" type="tel" name="workerPhone3" maxlength="4" outlined hide-details class="w-100"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <v-btn @click="certify" tile color="primary" class="w-100 w-sm-120px v-btn--input">본인인증</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
            <div class="table-style__row">
                <div class="table-style__th">정보수신방법 <span style="color:#ff0000;">*</span></div>
                <div class="table-style__td">
                    <v-row v-if="user?.notify" align="center" class="row--small">
                        <v-col v-for="(item, index) in user?.notify" :key="index" cols="auto">
                            <div class="d-flex flex-wrap align-center">
                                <v-checkbox v-model="item.enabled" :label="item.text" @input="input" hide-details class="d-inline-flex"/>
                                <template v-if="item.enabled">
                                    <v-text-field outlined v-model="item.contact" placeholder="연락처" @input="input" hide-details class="ml-8" />
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

        <!-- 하단버튼 -->
        <div class="bottom-button">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="cancel()"><span class="primary--text">취소하기</span></v-btn>
                <v-btn x-large color="primary" class="w-100 mw-220px" @click="submit()">회원가입</v-btn>
            </div>
        </div>

        <daum-postcode ref="daum-postcode" @change="setAddress" />
        <kcp-cert ref="kcp-cert" @input="verify"></kcp-cert>
    </div>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";

export default {
    components: {
        DaumPostcode,
        KcpCert,
    },
    props: ["value"],
    data() {
        return {
            user: this.$props.value || {},
        };
    },
    watch: {
        "user.email"() {
            this.$emit("input", this.user);
        },
        "user.email1"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },
        "user.email2"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },

        "user.workerEmail"() {
            this.$emit("input", this.user);
        },
        "user.workerEmail1"() {
            this.$set(this.user, "workerEmail", this.user.workerEmail1 && this.user.workerEmail2 ? `${this.user.workerEmail1}@${this.user.workerEmail2}` : null);
        },
        "user.workerEmail2"() {
            this.$set(this.user, "workerEmail", this.user.workerEmail1 && this.user.workerEmail2 ? `${this.user.workerEmail1}@${this.user.email2}` : null);
        },

        "user.phone"() {
            this.$emit("input", this.user);
        },
        "user.phone1"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone2"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone3"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },

        "user.workerPhone"() {
            this.$emit("input", this.user);
        },
        "user.workerPhone1"() {
            this.$set(this.user, "workerPhone", this.user.workerPhone1 && this.user.workerPhone2 && this.user.workerPhone3 ? `${this.user.workerPhone1}-${this.user.workerPhone2}-${this.user.workerPhone3}` : null);
        },
        "user.workerPhone2"() {
            this.$set(this.user, "workerPhone", this.user.workerPhone1 && this.user.workerPhone2 && this.user.workerPhone3 ? `${this.user.workerPhone1}-${this.user.workerPhone2}-${this.user.workerPhone3}` : null);
        },
        "user.workerPhone3"() {
            this.$set(this.user, "workerPhone", this.user.workerPhone1 && this.user.workerPhone2 && this.user.workerPhone3 ? `${this.user.workerPhone1}-${this.user.workerPhone2}-${this.user.workerPhone3}` : null);
        },
    },
    methods: {
        input() {
            this.$emit("input", this.user);
        },
        cancel() {
            this.$router.go(-1);
        },

        submit() {
            if (this.validate()) this.$emit("submit", this.user);
        },

        certify() {
            this.$refs["kcp-cert"].auth();
        },

        searchAddress() {
            this.$refs["daum-postcode"].open();
        },
        setAddress({ postcode, address }) {
            this.user.postcode = postcode;
            this.user.address1 = address;
        },

        verify(payload) {
            this.user._certification = payload._certification;
            var [phone1, phone2, phone3] = payload.phone?.phoneNumberFormat()?.split("-");
            this.user.phone1 = phone1;
            this.user.phone2 = phone2;
            this.user.phone3 = phone3;
        },

        validate() {
            try {
                if (!this.user.username) throw new Error("아이디를 입력해주세요.");
                if (!this.user.password) throw new Error("비밀번호를 입력해주세요.");

                if (!this.checkPassword(this.user.password)) return false;
                if (!this.user.passwordConfirm) throw new Error("비밀번호 확인을 입력해주세요.");
                if (this.user.password != this.user.passwordConfirm) throw new Error("비밀번호를 확인해주세요");

                if (!this.user.name) throw new Error("이름을 입력해주세요.");
                // if (!this.user.foundAt) throw new Error("기업설립일을 입력해주세요.");
                if (!this.user.businessRegisitrationNumber) throw new Error("사업자등록번호를 입력해주세요.");
                // if (!this.user.nation) throw new Error("국가를 입력해주세요.");
                if (!this.user.workerName) throw new Error("담당자 이름을 입력해주세요.");
                if (!this.user.workerEmail) throw new Error("담당자 이메일을 입력해주세요.");
                if (!this.user.workerPhone) throw new Error("담당자 연락처를 입력해주세요.");

                if (!this.user.phone1 || !this.user.phone2 || !this.user.phone3) throw new Error("연락처를 입력해주세요.");
                if (!this.user.email1 || !this.user.email2) throw new Error("이메일을 입력해주세요.");
                if (!this.user.postcode || !this.user.address1 || !this.user.address2) throw new Error("주소를 입력해주세요.");

                if (!this.user.notify.some(({ enabled }) => enabled)) throw new Error("정보수신방법을 입력해주세요.");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");

            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");

            return true;
        },
    },
};
</script>
